<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import { NoticeProductAvailableProps } from "./NoticeProductAvailable.props"
import { FormKitNode } from "@formkit/core"
import { useUserStore } from "~/stores/user"
import { storeToRefs } from "pinia"
import { customPaths } from "~/utils/constants"

const { sendEventErrorForm } = useGA4SiteEvents()
const props = defineProps<NoticeProductAvailableProps>()
const { sendSubscription } = useInStockSubscription()

const userStore = useUserStore()
const { user } = storeToRefs(userStore)
const emailState = useState("notification-subscription-mail", () => "")

const { closeModal } = useModal()
const isLogged = useIsLogged()
const emailField = ref<string>(user.value?.email ?? "")

const selectedProducts = ref<string[]>([])

const toggleSkuCode = (val: boolean, skuCode: string) => {
  selectedProducts.value = selectedProducts.value.filter((p) => p !== skuCode)

  if (val) selectedProducts.value.push(skuCode)
}
const skuState = useState("product-unavailable-state")

const isSubscribing = ref(false)

const handleNotifyMe = async () => {
  isSubscribing.value = true
  emailState.value = emailField.value
  // if a single product is overbooked, we still need to toggle it.
  // there is no front-end checkbox so we'll toggle it programmatically.
  if (props.notAvailableProducts?.length === 1) {
    toggleSkuCode(true, props.notAvailableProducts[0].productCode)
  }

  skuState.value = selectedProducts.value.length
    ? selectedProducts.value
    : skuState.value

  await sendSubscription()

  useTimeoutFn(() => (isSubscribing.value = false), 1000)
  closeModal(props.id)
}
const handleClose = () => {
  closeModal(props.id)
}
const { emailValidation } = useLoqate()
const emailCheck = async (node: FormKitNode) =>
  await emailValidation(node.value as string)
</script>

<template>
  <div class="modal-notice-product-available max-w-[500px]">
    
<FormKitLazyProvider config-file="true">
<DialogsAndModalsStandard :title="title" @on-close="handleClose">
      <template #content>
        <div class="flex flex-wrap gap-4 px-4 py-8 md:py-6">
          <h3 class="pig-bold md:dolphin-bold flex w-full items-center">
            <UtilsIcon
              name="CloseCircleFull.svg"
              color="negative-main"
              class="mr-2 inline-block h-6 w-6"
            />
            {{ $t("dialogsAndModals.productNotAvailable.title") }}
          </h3>

          <UtilsMarkdown
            class="beaver markdown__strong-beaver-bold mb-1 w-full"
            :content="description"
          />
          <div v-if="notAvailableProducts">
            <div
              v-for="product in notAvailableProducts"
              :key="product.title"
              class="flex items-center"
            >
              <ControlsCheckbox
                v-if="notAvailableProducts.length > 1"
                id="notify-product-not-available"
                name="notify-product-not-available"
                :model-value="selectedProducts.includes(product.productCode)"
                @update:modelValue="toggleSkuCode($event, product.productCode)"
              />
              <UtilsWrapperImage
                v-if="product.productImage"
                v-bind="product.productImage"
                class="w-20 flex-none"
              />
              <AppLink :to="product.path" class="ml-6 underline">
                {{ product.title }}
              </AppLink>
            </div>
          </div>

          <FormKit
            type="form"
            id="productNotificationAvailableForm"
            @submit-invalid="
              sendEventErrorForm('productNotificationAvailableForm')
            "
            form-class="filters-modal__form w-full flex flex-wrap gap-y-4"
            :actions="false"
            @submit="handleNotifyMe"
            #default="{ state: { valid } }"
          >
            <TextfieldsEmail
              :loqate-check="!isLogged"
              v-show="!isLogged"
              v-model="emailField"
              name="emailField"
              class="w-full"
              :label="$t('dialogsAndModals.productNotAvailable.yourEmail')"
            />
            <UtilsButton
              type="submit"
              :class="[
                'relative w-full',
                { '[&_.btn\\_\\_text]:opacity-0': isSubscribing }
              ]"
              :text="$t('dialogsAndModals.productNotAvailable.notifyMe')"
              :is-disabled="!valid"
            >
              <template #icon>
                <UtilsLoader
                  v-if="isSubscribing"
                  class="absolute inset-0 justify-center"
                />
              </template>
            </UtilsButton>
          </FormKit>

          <UtilsButton
            target="_blank"
            :path="customPaths.privacy"
            :force-self="false"
            :text="$t('dialogsAndModals.productNotAvailable.privacy')"
            class="custom-btn-no-padding custom-btn-mouse"
            theme="link-green"
          />
        </div>
      </template>
    </DialogsAndModalsStandard>
</FormKitLazyProvider>

  </div>
</template>
